import React, { useState } from "react";
import axios from "axios";
import Input from "./Input"; // Assuming Input component is in the same directory
import InputPasswd from "./InputPasswd"; // Assuming InputPasswd component is in the same directory

const LoginForm = ({ setIsLoggedIn }) => {
  const [_user, _setUser] = useState({ username: "", password: "" });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://team.mira.cloud/api/teleg_dev/v2.1/auth/login",
        {
          username: _user.username,
          password: _user.password,
        }
      );
      if (response.status === 200) {
        const { access_token } = response.data;
        localStorage.setItem("access_token", access_token);
        setIsLoggedIn(true);
      } else {
        alert("Invalid credentials");
      }
    } catch (error) {
      alert("Error logging in");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen border bg-gray-300">
      <div className="w-full max-w-lg">
        <form
          className="bg-white shadow-2xl rounded border-gray-300 px-8 pt-6 pb-8 mb-4"
          onSubmit={handleSubmit}
        >
          <div className="mb-4">
            <Input
              label="Логин"
              value={_user.username}
              inputCb={(e) => _setUser({ ..._user, username: e.target.value })}
              id="username"
              type="text"
              autocomplete="username"
              placeholder="someone@example.com"
            />
          </div>
          <div className="mb-4">
            <InputPasswd
              label="Пароль"
              value={_user.password}
              inputCb={(e) => _setUser({ ..._user, password: e.target.value })}
              id="password"
              autocomplete="current-password"
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
