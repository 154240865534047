import React from "react";

const DeveloperSummaryModal = ({
  developers,
  isDeveloperSummaryModalOpen,
  setIsDeveloperSummaryModalOpen,
  tasks, // Add tasks as a prop
}) => {
  const calculateDeveloperHours = (tasks) => {
    const developerHours = developers.reduce((acc, dev) => {
      if (dev.id !== "all") {
        acc[dev.id] = { total: 0, completed: 0 };
      }
      return acc;
    }, {});
    Object.values(tasks)
      .flat()
      .forEach((task) => {
        if (task.developer && developerHours[task.developer] !== undefined) {
          const taskHours = Number(task.hours);
          developerHours[task.developer].total += taskHours;
          if (tasks.items3.includes(task)) {
            developerHours[task.developer].completed += taskHours;
          }
        }
      });
    return developerHours;
  };

  const developerHours = calculateDeveloperHours(tasks);
  const totalHours = Object.values(developerHours).reduce(
    (acc, hours) => acc + hours.total,
    0
  );

  return (
    isDeveloperSummaryModalOpen && (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center p-4">
        <div className="bg-white p-4 rounded shadow-lg w-full max-w-md">
          <h2 className="text-lg font-bold mb-4">Сводка по разработчикам</h2>
          <div className="overflow-x-auto">
            <table className="w-full border-collapse text-sm">
              <thead>
                <tr>
                  <th className="border p-2">Разработчик</th>
                  <th className="border p-2">Суммарное время</th>
                  <th className="border p-2">Время выполненных задач</th>
                  <th className="border p-2">Процент выполнения</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(developerHours).map(([devId, hours]) => {
                  const completionPercentage =
                    hours.total > 0
                      ? Math.round(
                          ((hours.completed / hours.total) * 100) / 10
                        ) * 10
                      : 0;
                  return (
                    <tr key={devId}>
                      <td className="border p-2">
                        {developers.find((dev) => dev.id === devId)?.name}
                      </td>
                      <td className="border p-2">{hours.total}</td>
                      <td className="border p-2">{hours.completed}</td>
                      <td className="border p-2">{completionPercentage}%</td>
                    </tr>
                  );
                })}
                <tr>
                  <td className="border p-2 font-bold">Итого</td>
                  <td className="border p-2 font-bold">{totalHours}</td>
                  <td className="border p-2"></td>
                  <td className="border p-2"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mt-4 flex justify-end space-x-2">
            <button
              onClick={() => setIsDeveloperSummaryModalOpen(false)}
              className="p-2 bg-gray-500 text-white rounded"
            >
              Закрыть
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default DeveloperSummaryModal;
