import React, { useState, useEffect } from "react";
import axios from "axios";
import DeveloperSummaryModal from "./DeveloperSummaryModal";
import AddTaskModal from "./AddTaskModal";
import TaskBoard from "./TaskBoard1";
import axiosInstance from "../api/Axios"; // Import the axiosInstance
import AddIcon from "@mui/icons-material/Add";
import SummaryIcon from "@mui/icons-material/Summarize";

// Utility function to get initials from a name
const getInitials = (name) => {
  const [firstName, lastName] = name.split(" ");
  return `${firstName[0]}${lastName[0]}`;
};

// Fetch categories from the API
const fetchCategories = () => axiosInstance.get("/categories");

// Fetch developers from the API
const fetchDevelopers = () => axiosInstance.get("/devs");

// Fetch projects from the API
const fetchProjects = () => axiosInstance.get("/projects");

// Fetch tasks from the API
const fetchTasksFromAPI = () => axiosInstance.get("/gettasks2");

// Initialize empty tasks based on categories
const initializeEmptyTasks = (categories) => {
  return categories.reduce((acc, category) => {
    acc[category.id] = [];
    return acc;
  }, {});
};

const Main = () => {
  const [tasks, setTasks] = useState({});
  const [developers, setDevelopers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedDeveloper, setSelectedDeveloper] = useState("all");
  const [selectedProject, setSelectedProject] = useState("all");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeveloperSummaryModalOpen, setIsDeveloperSummaryModalOpen] =
    useState(false);
  const [newTask, setNewTask] = useState({
    title: "",
    description: "",
    developer: "none",
    project: "all",
    hours: 0,
  });
  const [isLoading, setIsLoading] = useState(true);

  // Fetch initial data (categories, developers, projects)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoriesResponse, developersResponse, projectsResponse] =
          await Promise.all([
            fetchCategories(),
            fetchDevelopers(),
            fetchProjects(),
          ]);

        setCategories(categoriesResponse.data);
        setDevelopers([
          { id: "all", name: "Все разработчики" },
          ...developersResponse.data,
        ]);
        setProjects([
          { id: "all", name: "Все проекты" },
          ...projectsResponse.data,
        ]);

        const initialEmptyTask = initializeEmptyTasks(categoriesResponse.data);
        setTasks(initialEmptyTask);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // Fetch tasks from the API
  const fetchTasks = async () => {
    try {
      const response = await fetchTasksFromAPI();
      const newState = updateDisplay(response.data);
      setTasks(newState);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  // Update task display based on selected developer and project
  const updateDisplay = (tasks) => {
    const newState = { ...tasks };
    for (const key in newState) {
      newState[key] = newState[key].map((task) => {
        const developerMatch =
          selectedDeveloper === "all" || task.developer === selectedDeveloper;
        const projectMatch =
          selectedProject === "all" || task.project === selectedProject;
        return { ...task, display: developerMatch && projectMatch };
      });
    }
    return newState;
  };

  // Update tasks display when selected developer or project changes
  useEffect(() => {
    const newState = updateDisplay(tasks);
    setTasks(newState);
    console.log("Tasks updated");
  }, [selectedDeveloper, selectedProject]);

  // Fetch tasks on component mount
  useEffect(() => {
    fetchTasks();
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-300">
        Loading...
      </div>
    );
  }

  return (
    <div className="bg-gray-300 min-h-screen flex flex-col items-start justify-center">
      <div className="w-full bg-gray-100 p-2 shadow flex flex-col md:flex-row justify-between space-y-2 md:space-y-0">
        <select
          value={selectedProject}
          onChange={(e) => setSelectedProject(e.target.value)}
          className="p-1 border rounded w-full md:w-auto text-sm"
        >
          {projects.map((proj) => (
            <option key={proj.id} value={proj.id}>
              {proj.name}
            </option>
          ))}
        </select>
        <select
          value={selectedDeveloper}
          onChange={(e) => setSelectedDeveloper(e.target.value)}
          className="p-1 border rounded w-full md:w-auto text-sm"
        >
          <option value="all">Все разработчики</option>
          {developers
            .filter((dev) => dev.id !== "all")
            .map((dev) => (
              <option key={dev.id} value={dev.id}>
                {dev.name}
              </option>
            ))}
        </select>
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 w-full md:w-auto">
          <button
            onClick={() => setIsModalOpen(true)}
            className="p-2 bg-blue-500 text-white rounded w-full md:w-auto flex items-center justify-center"
          >
            <AddIcon />
          </button>
          <button
            onClick={() => setIsDeveloperSummaryModalOpen(true)}
            className="p-2 bg-green-500 text-white rounded w-full md:w-auto flex items-center justify-center"
          >
            <SummaryIcon />
          </button>
        </div>
      </div>
      <TaskBoard
        tasks={tasks}
        setTasks={setTasks}
        developers={developers}
        projects={projects}
        categories={categories}
        setSelectedTask={setSelectedTask}
        fetchTasks={fetchTasks}
      />
      <AddTaskModal
        developers={developers}
        projects={projects}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        newTask={newTask}
        setNewTask={setNewTask}
        setTasks={setTasks}
        fetchTasks={fetchTasks}
      />
      <DeveloperSummaryModal
        developers={developers}
        isDeveloperSummaryModalOpen={isDeveloperSummaryModalOpen}
        setIsDeveloperSummaryModalOpen={setIsDeveloperSummaryModalOpen}
        tasks={tasks}
      />
    </div>
  );
};

export default Main;
