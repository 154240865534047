import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import "tailwindcss/tailwind.css";
import AddTaskModal from "./AddTaskModal";
import axiosInstance from "../api/Axios"; // Import the axiosInstance
import TaskCard from "./TaskCard"; // Import the TaskCard component
import axios from "axios"; // Import axios for fetching funny text

// Reorder tasks within the same category
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

// Move tasks between different categories
const move = (sourceTasks, destinationTasks, source, destination) => {
  const sourceTasksClone = [...sourceTasks];
  const destinationTasksClone = [...destinationTasks];
  const [movedTask] = sourceTasksClone.splice(source.index, 1);
  movedTask.category = destination.droppableId; // Update the category
  destinationTasksClone.splice(destination.index, 0, movedTask);
  return {
    [source.droppableId]: sourceTasksClone,
    [destination.droppableId]: destinationTasksClone,
  };
};

const TaskBoard = ({
  tasks,
  setTasks,
  developers,
  projects,
  categories,
  setSelectedTask,
  fetchTasks,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [taskToEdit, setTaskToEdit] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // New state for loading
  const [loadingText, setLoadingText] = useState("🤡 Loading..."); // New state for loading text

  const [newTask, setNewTask] = useState({
    title: "",
    description: "",
    developer: "none",
    project: "all",
    hours: 0,
  });

  // Fetch a funny text
  const fetchFunnyText = async () => {
    //  try {
    //    const response = await axios.get(
    //      "https://official-joke-api.appspot.com/random_joke"
    //    );
    //    const joke = `${response.data.setup} 😂 ${response.data.punchline}`;
    //    setLoadingText(joke);
    //  } catch (error) {
    //    setLoadingText("🤡 Failed to load joke, please try again later.");
    //  }
  };

  // Update tasks on the server
  const updateTasks = async (updatedTaskData) => {
    setTasks(updatedTaskData);
    console.log("Updated Task Data:", updatedTaskData);
    try {
      const response = await axiosInstance.post(
        "/updatetasks",
        updatedTaskData
      );
      return response.data;
    } catch (error) {
      console.error("Error updating tasks:", error);
      throw error;
    }
  };

  // Handle drag and drop events
  const onDragEnd = async (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    setIsLoading(true); // Set loading to true
    await fetchFunnyText(); // Fetch a new funny text
    try {
      let newState;
      if (source.droppableId === destination.droppableId) {
        const items = reorder(
          tasks[source.droppableId],
          source.index,
          destination.index
        );
        newState = { ...tasks, [source.droppableId]: items };
      } else {
        const sourceTasks = tasks[source.droppableId];
        const destinationTasks = tasks[destination.droppableId];
        if (sourceTasks && destinationTasks) {
          const result = move(
            sourceTasks,
            destinationTasks,
            source,
            destination
          );
          newState = { ...tasks, ...result };
          if (destination.droppableId === "items3") {
            const completedTask =
              result[destination.droppableId][destination.index];
            completedTask.completedAt = new Date().toISOString(); // Add current time of completion
            await sendTaskToTelegram(completedTask._id);
          }
        }
      }
      await updateTasks(newState);
    } catch (error) {
      console.error("Error during drag and drop operation:", error);
    } finally {
      console.log("onDragEnd function has completed.");
      await fetchTasks();
      setIsLoading(false); // Set loading to false
      setLoadingText("🤡 Loading..."); // Reset the loading text
    }
  };

  // Send task to Telegram
  const sendTaskToTelegram = async (taskId) => {
    try {
      await axiosInstance.post(`/sendtask/${taskId}`);
    } catch (error) {
      console.error("Error archiving task:", error);
    }
  };

  // Handle developer change
  const handleDeveloperChange = async (taskId, developerId) => {
    const newState = { ...tasks };
    let updatedTask = null;
    for (const key in newState) {
      newState[key] = newState[key].map((task) => {
        if (task.id === taskId) {
          updatedTask = { ...task, developer: developerId };
          return updatedTask;
        }
        return task;
      });
    }
    await updateTasks(newState);
    if (updatedTask) {
      await fetchTasks();
    }
    setSelectedTask(null);
  };

  // Handle project change
  const handleProjectChange = async (taskId, projectId) => {
    const newState = { ...tasks };
    let updatedTask = null;
    for (const key in newState) {
      newState[key] = newState[key].map((task) => {
        if (task.id === taskId) {
          updatedTask = { ...task, project: projectId };
          return updatedTask;
        }
        return task;
      });
    }
    await updateTasks(newState);
    if (updatedTask) {
      await fetchTasks();
    }
  };

  // Handle hours change
  const handleHoursChange = async (taskId, hours) => {
    const newState = { ...tasks };
    let updatedTask = null;
    for (const key in newState) {
      newState[key] = newState[key].map((task) => {
        if (task.id === taskId) {
          updatedTask = { ...task, hours: hours };
          return updatedTask;
        }
        return task;
      });
    }
    await updateTasks(newState);
    if (updatedTask) {
      await fetchTasks();
    }
  };

  // Handle task archiving
  const handleArchiveTask = async (taskId) => {
    try {
      await axiosInstance.post(`/movetask/${taskId}`);
      await fetchTasks();
    } catch (error) {
      console.error("Error archiving task:", error);
    }
  };

  // Open edit modal
  const openEditModal = (task) => {
    setTaskToEdit(task);
    setNewTask(task);
    setIsModalOpen(true);
  };

  return (
    <div className="relative">
      {isLoading && (
        <div className="absolute inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="loader" style={{ color: "#f0f0f0" }}>
            {loadingText}
          </div>
        </div>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="flex flex-col md:flex-row w-full h-full">
          {categories.map((category) => (
            <Droppable key={category.id} droppableId={category.id}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="p-4 w-full md:w-1/3 h-screen overflow-y-auto relative"
                >
                  <div className="">
                    <h2 className="text-lg font-bold mb-4 text-center">
                      {category.title}
                    </h2>
                  </div>
                  <div className="space-y-2">
                    {tasks[category.id] &&
                      tasks[category.id].map((item, index) =>
                        item.display ? (
                          <TaskCard
                            key={item.id}
                            item={item}
                            index={index}
                            developers={developers}
                            projects={projects}
                            handleDeveloperChange={handleDeveloperChange}
                            handleProjectChange={handleProjectChange}
                            handleHoursChange={handleHoursChange}
                            handleArchiveTask={handleArchiveTask}
                            openEditModal={openEditModal}
                          />
                        ) : (
                          <div key={item.id}></div>
                        )
                      )}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          ))}
        </div>
        {isModalOpen && (
          <AddTaskModal
            developers={developers}
            projects={projects}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            newTask={newTask}
            setNewTask={setNewTask}
            setTasks={setTasks}
            taskToEdit={taskToEdit}
            fetchTasks={fetchTasks}
          />
        )}
      </DragDropContext>
    </div>
  );
};

export default TaskBoard;
