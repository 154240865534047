import React from 'react';

const Input = ({ label, value, inputCb, id, type = 'text', placeholder, autocomplete = '' }) => {
  return (
    <>
      <label class="block text-gray-700 text-sm  ml-2 mb-2" for={id}>
        {label}
      </label>
      <input
        value={value}
        onChange={(e) => inputCb(e)}
        class="appearance-none border border-gray-300 pl-4 rounded-full w-full h-10 px-3 text-gray-700 leading-tight focus:outline-none"
        id={id}
        type={type}
        placeholder={placeholder}
        autocomplete={autocomplete}
        name={id}></input>
    </>
  );
};

export default Input;
