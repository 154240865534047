import React, { useState } from "react";
import axiosInstance from "../api/Axios"; // Adjust the import path accordingly

const AddTaskModal = ({
  developers,
  projects,
  isModalOpen,
  setIsModalOpen,
  newTask,
  setNewTask,
  setTasks,
  taskToEdit,
  fetchTasks,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const editTask = async (taskId, updatedTaskData) => {
    try {
      console.log("Editing task with ID:", taskId); // Log the taskId
      const response = await axiosInstance.put(
        `/edittask/${taskId}`,
        updatedTaskData
      );
      return response.data;
    } catch (error) {
      console.error("Error editing task:", error);
      throw error;
    }
  };

  const handleAddTask = async () => {
    if (newTask.title.trim() === "") {
      alert("Название задачи не может быть пустым");
      return;
    }
    if (newTask.developer === "none") {
      alert("Необходимо выбрать разработчика");
      return;
    }
    if (newTask.project === "all") {
      alert("Необходимо выбрать проект");
      return;
    }
    if (newTask.hours <= 0) {
      alert("Количество часов должно быть больше нуля");
      return;
    }

    setIsLoading(true);
    const currentTime = new Date().toISOString();

    if (taskToEdit) {
      // Edit existing task
      const updatedTaskData = { ...newTask, editedAt: currentTime };
      try {
        await editTask(taskToEdit._id, updatedTaskData);
        await fetchTasks();
      } catch (error) {
        alert("Ошибка при редактировании задачи" + error);
        setIsLoading(false);
        return;
      }
    } else {
      // Add new task
      const newTaskId = `new-${Date.now()}`;
      const newTaskData = {
        ...newTask,
        id: newTaskId,
        category: "items1",
        createdAt: currentTime,
      };

      try {
        await axiosInstance.post("/addtask", newTaskData);

        // Fetch tasks after adding a new task
        await fetchTasks();
      } catch (error) {
        console.error("Error adding task:", error);
        alert("Ошибка при добавлении задачи");
        setIsLoading(false);
        return;
      }
    }

    setIsLoading(false);
    setIsModalOpen(false);
    setNewTask({
      title: "",
      description: "",
      developer: "none",
      project: "all",
      hours: 0,
    });
  };

  return (
    isModalOpen && (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center p-4">
        <div className="bg-white p-4 rounded shadow-lg w-full max-w-md">
          <h2 className="text-lg font-bold mb-4">
            {taskToEdit ? "Редактировать задачу" : "Добавить задачу"}
          </h2>
          <div className="space-y-2">
            <input
              type="text"
              placeholder="Название задачи"
              value={newTask.title}
              onChange={(e) =>
                setNewTask({ ...newTask, title: e.target.value })
              }
              className="p-2 border rounded w-full"
            />
            <textarea
              placeholder="Описание задачи"
              value={newTask.description}
              onChange={(e) =>
                setNewTask({ ...newTask, description: e.target.value })
              }
              className="p-2 border rounded w-full"
            />
            <select
              value={newTask.developer}
              onChange={(e) =>
                setNewTask({ ...newTask, developer: e.target.value })
              }
              className="p-2 border rounded w-full"
            >
              <option value="none">Назначить разработчика</option>
              {developers
                .filter((dev) => dev.id !== "all")
                .map((dev) => (
                  <option key={dev.id} value={dev.id}>
                    {dev.name}
                  </option>
                ))}
            </select>
            <select
              value={newTask.project}
              onChange={(e) =>
                setNewTask({ ...newTask, project: e.target.value })
              }
              className="p-2 border rounded w-full"
            >
              {projects.map((proj) => (
                <option key={proj.id} value={proj.id}>
                  {proj.name}
                </option>
              ))}
            </select>
            <input
              type="number"
              step="0.1"
              placeholder="Часы"
              value={newTask.hours}
              onChange={(e) =>
                setNewTask({ ...newTask, hours: parseFloat(e.target.value) })
              }
              className="p-2 border rounded w-full"
            />
          </div>
          <div className="mt-4 flex justify-end space-x-2">
            <button
              onClick={() => setIsModalOpen(false)}
              className="p-2 bg-gray-500 text-white rounded"
              disabled={isLoading}
            >
              Отмена
            </button>
            <button
              onClick={handleAddTask}
              className="p-2 bg-blue-500 text-white rounded"
              disabled={isLoading}
            >
              {isLoading
                ? "Загрузка..."
                : taskToEdit
                ? "Сохранить"
                : "Добавить"}
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default AddTaskModal;
